import React from 'react';
import styled from '@emotion/styled';
import { MfaMethod } from 'scenes/SignInWithMfa';

const OptionRow = styled.span`
  display: flex;
  align-items: center;
`;

const OptionUnit = styled.span`
  padding: var(--size-xs);
`;

const LabelSubtext = styled.span`
  color: var(--color-text-medium);
  display: contents;
`;

const StyledBold = styled.span`
  width: 100%;
  font-weight: var(--font-weight-bold);
  display: contents;
`;

type Props = {
  id: MfaMethod;
  icon: JSX.Element;
  label: string;
  subtext: string;
  setMfaMethod: (method: MfaMethod) => void;
  mfaMethod: MfaMethod;
};

const MfaOption = ({
  id, icon, label, subtext, setMfaMethod, mfaMethod,
}: Props) => (
  <label htmlFor={id}>
    <OptionRow>
      <OptionUnit>
        <input
          data-testid={`mfa-${id}-option`}
          checked={mfaMethod === id}
          name="mfa"
          type="radio"
          id={id}
          value={id}
          onChange={() => setMfaMethod(id)}
        />
      </OptionUnit>
      <OptionUnit>
        {icon}
      </OptionUnit>
      <OptionUnit>
        <StyledBold>{label}</StyledBold>
        <br />
        <LabelSubtext>{subtext}</LabelSubtext>
      </OptionUnit>
    </OptionRow>
  </label>
);

export default MfaOption;
