import React, {
  Suspense, useEffect, useCallback,
} from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'react-router';
import { connect } from 'react-redux';

import { CacheProvider } from '@emotion/react';
import {
  Footer,
  Header,
  Loader,
} from 'components';
import Amplitude from 'lib/amplitude';
import trackingService from 'services/TrackingService';
import { resetUIState } from 'resources/ui/ducks';
import ErrorReporter from 'components/ErrorReporter';
import emotionCache from './lib/emotionCache';
import 'assets/stylesheets/styles.css';
import Routes from './routes';

const StyledApp = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: var(--font-family);
  font-size: var(--font-size-small-paragraph)

  @media (min-width: 768px){
    background: var(--color-background);
    font-size: inherit;
    line-height: inherit;
  }
`;

const StyledMain = styled.div`
  flex: 1 0 auto;
  width:100%;
  margin: 0 auto;
  box-sizing: border-box;
`;

/*
Introduced this AppWrapper to fix the sticky footer that was snot working in IE11.
*/
const AppWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const App = ({ dispatch }) => {
  const location = useLocation();

  const onRouteChange = useCallback(() => {
    trackingService.trackPageView();
    dispatch(resetUIState());
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      await trackingService.init();
      if (typeof window !== 'undefined') window.amplitude = await Amplitude.init(); // create the shared instance
    })();
  }, []);

  useEffect(() => {
    onRouteChange();
  }, [location, onRouteChange]);

  const currentYear = new Date().getUTCFullYear().toString();

  return (
    <CacheProvider value={emotionCache}>
      <AppWrapper>
        <StyledApp>
          <Header />
          <ErrorReporter>
            <StyledMain>
              <Suspense fallback={<Loader />}>
                <Routes />
              </Suspense>
            </StyledMain>
          </ErrorReporter>
          <Footer currentYear={currentYear} />
        </StyledApp>
      </AppWrapper>
    </CacheProvider>
  );
};

const mapStateToProps = () => ({});

const ConnectedApp = connect(mapStateToProps)(App);

export default ConnectedApp;
