import { Amplify } from 'aws-amplify';

const userPoolId = process.env.CUSTOMER_ACCOUNT_USER_POOL_ID;
const userPoolClientId = process.env.CUSTOMER_ACCOUNT_WEB_CLIENT_ID;
const identityPoolId = process.env.CUSTOMER_ACCOUNT_IDENTITY_POOL_ID;

// MFA Pool attributes
// const userPoolId = 'eu-west-1_t54VASJOo';
// const userPoolClientId = '3ncsjjhhva2vc5tq30fi1mjfkn';
// const identityPoolId = 'eu-west-1:93f34b22-1a18-434e-9f62-abb8a4413877';

const init = (): void => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId,
        userPoolClientId,
        identityPoolId,
        loginWith: {
          email: true,
        },
      },
    },
  });
};

export default { init };
