import { getApiBaseUrl } from 'config/api';
import { getJwT } from 'services/AuthenticationService';
import { MfaMethod } from 'scenes/SignInWithMfa';
import fetchWrapper from './fetch';

export const createIdentity = async (
  newAccountId,
  policyNumber,
  contactDetailsForUpdate = {},
) => {
  const response = await fetchWrapper(`${getApiBaseUrl()}/v3/identities`, {
    headers: {
      Authorization: `Bearer ${getJwT()}`,
    },
    body: JSON.stringify({
      account_id_for_linking: newAccountId,
      policy_number: policyNumber,
      contact_details_for_update: contactDetailsForUpdate,
    }),
    method: 'POST',
  });

  return response;
};

export const getAuthToken = async () => {
  const { jwt } = await fetchWrapper(`${getApiBaseUrl()}/v3/auth/encrypted-jwt`, {
    headers: {
      Authorization: `Bearer ${getJwT()}`,
    },
    method: 'GET',
  });

  return jwt;
};

export const syncCognitoPhoneNumber = async (email: string) => {
  const response = await fetchWrapper(`${getApiBaseUrl()}/v3/accounts/sync-phone-number`, {
    body: JSON.stringify({ email }),
    method: 'POST',
  });

  return response;
};

export const autoLinkPolicies = async () => {
  fetchWrapper(`${getApiBaseUrl()}/v3/identities/auto-link`, {
    headers: {
      Authorization: `Bearer ${getJwT()}`,
    },
    method: 'POST',
  });
};

export const enableMfaWithPreference = async (email: string, preference: MfaMethod) => {
  const response = await fetchWrapper(`${getApiBaseUrl()}/v3/accounts/mfa-preference`, {
    body: JSON.stringify({ email, preference }),
    method: 'PUT',
  });

  return response;
};

export default {
  createIdentity, getAuthToken, syncCognitoPhoneNumber, enableMfaWithPreference, autoLinkPolicies,
};
