/* eslint-disable no-constant-condition, func-style */
import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import contactDetailsApi from 'api/contactDetails';
import { refreshUserSession, signOut } from 'services/AuthenticationService';
import TrackingService from 'services/TrackingService';
import { signUp } from 'api/authentication';
import errorMessages from 'config/validation/errorMessages';
import newRelic from 'lib/newRelic';
import {
  USER__FETCH_CONTACT_DETAILS,
  fetchCurrentContactDetailsComplete,
  USER__UPDATE_CONTACT_DETAILS,
  updateCurrentContactDetailsComplete,
  USER__CREATE_PASSWORD,
} from './ducks';
import {
  updateUIState, toggleAccountDetailsFieldOpenState,
} from '../ui/ducks';
import getAirbrakeNotifier from '../../services/AirbrakeNotifierService';


export const fetchCurrentContactDetailsSaga = function* fetchCurrentContactDetails(): SagaIterator {
  try {
    const data = yield call(
      contactDetailsApi.get,
    );

    yield put(fetchCurrentContactDetailsComplete(data));
  } catch (err: any) {
    yield put(fetchCurrentContactDetailsComplete(err));
  }
};

export const updateCurrentContactDetailsSaga = function* updateCurrentContactDetails(action): SagaIterator {
  const {
    payload: newContactDetails, meta: {
      successMessage, fieldName,
    },
  } = action;

  try {
    yield put(updateUIState({ loading: true, message: null }));
    /*
     TODO make sure that the payload is converted to the contact
     details set and doesn't contain extra data (e.g. token, confirmPassword)
     */

    const data = yield call(
      contactDetailsApi.update,
      newContactDetails,
    );
    const message = successMessage || 'Your contact details have been updated successfully!';

    if (fieldName && fieldName.toLowerCase() === 'emailaddress') {
      yield call(refreshUserSession);
    }
    yield put(updateCurrentContactDetailsComplete(data));
    yield put(updateUIState({ message, loading: false, level: 'success' }));
    yield put(toggleAccountDetailsFieldOpenState(fieldName));
    if (fieldName && fieldName.toLowerCase() === 'emailaddress') {
      yield call(signOut);
      window.location.reload();
    }
  } catch (err: any) {
    yield put(updateCurrentContactDetailsComplete(err));

    const message = (Array.isArray(err.errors) && err.errors.length > 0)
      ? err.errors.map(e => e.message).join(', ')
      // backup error in case the server doesn't return the validation errors as expected
      : errorMessages.unpredictableError;

    yield put(updateUIState({ message, level: 'error', loading: false }));
  }
};


export const createPasswordSaga = function* createPassword(action): SagaIterator {
  const { password, token, emailAddress } = action.payload;
  const { onSuccess } = action.meta;

  let loading = true;
  let message: string | JSX.Element | null = null;
  let level = 'success';

  yield put(updateUIState({ loading, message }));

  try {
    yield call(signUp, { password, token });
    yield call(onSuccess);
    loading = false;
  } catch (err: any) {
    level = 'error';
    let failureReason: string | null = null;

    if (err.code === 'invalid_password') {
      message = errorMessages.password.unsafePassword;
    } else if (err.code === 'too_many_customer_accounts') {
      failureReason = 'Customer not eligible - too many customer accounts';
      message = errorMessages.customerNotEligibleMessage;
    } else {
      // unexpected errors
      yield call(newRelic.logError, err);

      if (err.name !== 'FetchError') {
        const airbrake = getAirbrakeNotifier();

        yield call(airbrake.notify.bind(airbrake), {
          error: err,
          params: { source: 'createPasswordSaga', url: err.url },
        });
      }
    }

    // When account-api-gw returns (422) UnprocessableEntityError
    // without any error message, we can assume that AAG will publish the event
    // with more details.
    if (!! message && err.name !== 'UnprocessableEntityError') {
      yield call(TrackingService.fireEvent,
        'account_sign_up_failed',
        {
          failureReason: failureReason || message,
          emailAddress,
        });
    }
    message ||= errorMessages.generic;
    loading = false;
  }
  yield put(updateUIState({ message, loading, level }));
};

export default function* rootUserSaga(): SagaIterator {
  yield takeLatest(USER__FETCH_CONTACT_DETAILS, fetchCurrentContactDetailsSaga);
  yield takeLatest(USER__UPDATE_CONTACT_DETAILS, updateCurrentContactDetailsSaga);
  yield takeLatest(USER__CREATE_PASSWORD, createPasswordSaga);
}
