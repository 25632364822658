import React, { ReactNode, Ref, forwardRef } from 'react';
import { Button, DropdownMenu } from '@simplybusiness/mobius';
import { Link } from 'react-router';
import styled from '@emotion/styled';

interface MenuItemProps {
  href: string;
  onClick?: () => void;
  children: ReactNode;
  button: boolean;
}

const StyledLink = styled(Link)`
  color: var(--color-text-medium);
  text-decoration: none;
`;

const StyledButton = styled(Button)`
  color: var(--color-text-medium);
  text-decoration: none !important;
  border-radius: 0;
  width: 100%;
  text-align: left;
`;

const MenuItem = forwardRef((props: MenuItemProps, ref: Ref<HTMLLIElement>) => {
  const {
    href, onClick, children, button, ...rest
  } = props;
  const isActive = window.location.pathname === href;

  return (
    <DropdownMenu.Item ref={ref} {...rest} active={isActive}>
      { button ? (
        <StyledButton variant="link" onClick={onClick}>
          {children}
        </StyledButton>
      ) : (
        <StyledLink to={href} onClick={onClick}>
          {children}
        </StyledLink>
      )}
    </DropdownMenu.Item>
  );
});

export default MenuItem;
