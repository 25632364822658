import React, { useEffect } from 'react';
import {
  Button, Text, Divider,
} from '@simplybusiness/mobius';
import styled from '@emotion/styled';
import Chevron from 'assets/svgs/Chevron';
import MfaPhone from 'assets/svgs/MfaPhone';
import MfaEmail from 'assets/svgs/MfaEmail';
import { onlyUSorLocalUStraffic } from 'services/ConfigurationService';
import { MfaMethod } from 'scenes/SignInWithMfa';
import trackingService from 'services/TrackingService';
import MfaOption from './MfaOption';

type Props = {
  onContinue: () => void;
  setMfaMethod: (method: MfaMethod) => void;
  mfaMethod: MfaMethod;
  showBackButton: boolean;
}

const StyledButton = styled(Button)`
  display: block;
  margin-top: var(--size-md);
  margin-bottom: var(--size-md);
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const BackButton = styled.button`
  height: 24px;
  width: 24px;
  margin-right: var(--size-sm);
`;

const StyledHeader = styled(Text)`
  text-align: center;
  margin-bottom: var(--size-md);
`;

const StyledText = styled(Text)`
  color: var(--color-text-medium);
  display: contents;
`;

const StyledConsentText = styled(Text)`
  color: var(--color-text-medium);
  font-size: .9rem;
  margin-bottom: 2rem;
`;

const CtaRow = styled.span`
  display: flex;
  align-items: center;
`;

const MfaMethodSelector = ({
  onContinue, setMfaMethod, mfaMethod, showBackButton,
}: Props): JSX.Element => {
  const useAnotherEmailLink = `${process.env.CUSTOMER_ACCOUNT_DOCUMENTS_SERVER_URI}/manage_your_policy`;

  useEffect(() => {
    trackingService.fireEvent('user_prompted_for_mfa_selection');
  }, []);

  return (
    <div data-testid="mfa-method-selector">
      <StyledHeader className="h1">Account Authentication</StyledHeader>
      <Text className="h2">Sign in with MFA</Text>
      <StyledText className="p">
        Your account requires multi-factor authentication (MFA).
        To proceed, select the authentication method you prefer.
      </StyledText>
      <Divider />
      <MfaOption
        id="SMS"
        icon={<MfaPhone />}
        label="Phone"
        subtext="Authenticate using a code sent via text message to your phone"
        setMfaMethod={setMfaMethod}
        mfaMethod={mfaMethod}
      />
      <Divider />
      <MfaOption
        id="EMAIL"
        icon={<MfaEmail />}
        label="Email"
        subtext="Authenticate using a code sent via email"
        setMfaMethod={setMfaMethod}
        mfaMethod={mfaMethod}
      />
      <StyledButton data-testid="continue-to-code-entry" onClick={onContinue}>
        Continue
      </StyledButton>
      {onlyUSorLocalUStraffic() && (
        <StyledConsentText>
          By clicking Continue, you consent to receive a one-time passcode
          to the email address or phone number associated with your account
          to complete your login. Message and data rates may apply.
        </StyledConsentText>
      )}
      {showBackButton && (
        <CtaRow>
          <BackButton className="icon-button">
            <Chevron />
          </BackButton>
          <Button variant="link" onClick={() => window.location.replace(useAnotherEmailLink)}>
            Use another email address
          </Button>
        </CtaRow>
      )}
      <Divider />
    </div>
  );
};

export default MfaMethodSelector;
