import {
  useState, FC, ReactElement, useEffect, useCallback,
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { getCurrentUserInfo } from 'services/AuthenticationService';
import { USER__STORE_CURRENT_JWT_TOKEN } from 'resources/user/ducks';
import store from 'boot/store';
import { initZendeskChatScript } from 'lib/zendeskChat';
import { fetchPolicies, resetPolicies } from 'resources/policies/ducks';
import type Policy from 'models/Policy';

interface Props {
  policies?: Policy[];
  children: ReactElement;
}

export const PrivateRoute: FC<Props> = ({
  children,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const storeCurrentUserInfo = (currentUserInfo: Object): void => {
    store.dispatch({
      type: USER__STORE_CURRENT_JWT_TOKEN,
      payload: currentUserInfo,
    });
  };

  const onAuthenticationError = () => {
    store.dispatch(resetPolicies());
    // `isAuthenticated: false` will trigger a redirect in the render method
    setIsAuthenticated(false);
    setLoaded(true);
  };

  const authenticate = useCallback(async () => {
    const currentUserInfo = await getCurrentUserInfo();

    storeCurrentUserInfo(currentUserInfo);

    setIsAuthenticated(true);
    setLoaded(true);
  }, []);

  const fetchPoliciesAtPageLoad = useCallback(() => {
    store.dispatch(fetchPolicies());
  }, []);

  useEffect(() => {
    authenticate()
      .then(() => {
        if (window.isZendeskChatLoaded) return;

        initZendeskChatScript();
        window.isZendeskChatLoaded = true;
      })
      .then(() => fetchPoliciesAtPageLoad())
      .catch(() => onAuthenticationError());
  }, [
    authenticate,
    fetchPoliciesAtPageLoad,
  ]);

  useEffect(() => {
    getCurrentUserInfo()
      .then((currentUserInfo) => {
        storeCurrentUserInfo(currentUserInfo);
      })
      .catch(() => {
        if (isAuthenticated) setIsAuthenticated(false);
      });
  }, [location, isAuthenticated]);

  const redirectToSignIn = () => {
    localStorage.setItem('redirectTo', window.location.pathname);
    navigate('/sign-in');

    return null;
  };

  if (! loaded) return null;

  return (
    isAuthenticated === true
      ? children
      : redirectToSignIn()
  );
};

export default PrivateRoute;
