import {
  signIn as signInGen2, signOut as signOutGen2, confirmResetPassword, fetchAuthSession, AuthSession, SignInOutput,
  confirmUserAttribute,
} from 'aws-amplify/auth';
import {
  CurrentUserInfo,
  USER__DELETE_CURRENT_JWT_TOKEN,
  USER__STORE_CURRENT_JWT_TOKEN,
  storeCurrentUserInfo,
} from 'resources/user/ducks';
import { getUser } from 'resources/user/selectors';
import store from 'boot/store';
import newRelic from 'lib/newRelic';
import { resetPolicies } from 'resources/policies/ducks';
import { syncCognitoPhoneNumber } from 'api/identities';

const sanitisedPassword = (password: string): string => password.trim();

const sanitisedEmail = (email: string): string => email.toLowerCase();

const codeDestination = (response: SignInOutput) => {
  if (response.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE'
    || response.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_EMAIL_CODE') {
    return response.nextStep.codeDeliveryDetails?.destination;
  }

  return null;
};

export const buildMfaUrl = (response: SignInOutput, source: string) => {
  const nextStep = response.nextStep.signInStep;
  const baseUrl = `/sign-in/mfa?sign_in_step=${nextStep}&sign_in_source=${source}`;

  const destination = codeDestination(response);

  return `${baseUrl}${destination ? `&code_destination=${destination}` : ''}`;
};

// issue on local when signing in with gen 2, cognito keys are present which throws an error when calling the function
const clearCognitoKeys = (): void => {
  Object.keys(localStorage)
    .filter(key => key.startsWith('CognitoIdentityServiceProvider'))
    .forEach(key => localStorage.removeItem(key));
};

const extractSession = (session: AuthSession): CurrentUserInfo => {
  if (! session.tokens || ! session.tokens.accessToken || ! session.tokens.idToken) {
    throw new Error('User is not signed in');
  }

  const accessToken = { jwtToken: session.tokens.accessToken.toString() };
  const idToken = { jwtToken: session.tokens.idToken.toString() };
  const attributes = { email_verified: session.tokens.idToken.payload.email_verified === true || false };

  return { signInUserSession: { accessToken, idToken }, attributes };
};

export async function getCurrentUserInfo(): Promise<CurrentUserInfo> {
  const session = await fetchAuthSession();

  return extractSession(session);
}

export const verifyChangedEmail = (code: string): Promise<void> => (
  confirmUserAttribute({ confirmationCode: code, userAttributeKey: 'email' })
);

export const signIn = async (
  email: string, password: string, checkVerifiedEmail = false,
): Promise<SignInOutput> => {
  clearCognitoKeys();
  await syncCognitoPhoneNumber(sanitisedEmail(email));
  const signInResponse = await signInGen2({ username: sanitisedEmail(email), password: sanitisedPassword(password) });

  if (signInResponse.nextStep.signInStep === 'DONE') {
    const currentUserInfo = await getCurrentUserInfo();

    if (checkVerifiedEmail && currentUserInfo.attributes) {
      const emailVerified = currentUserInfo.attributes.email_verified;
      const normalizedEmailVerified = emailVerified.toString().toLowerCase();

      if (normalizedEmailVerified !== 'true') {
        throw new Error('This email address has not been verified yet');
      }
    }
    store.dispatch({
      type: USER__STORE_CURRENT_JWT_TOKEN,
      payload: currentUserInfo,
    });
  }

  return signInResponse;
};

export const resetForgottenPassword = async (
  email: string,
  code,
  password: string,
): Promise<void> => {
  await confirmResetPassword({ username: email, confirmationCode: code, newPassword: sanitisedPassword(password) });
};

export const refreshUserSession = async (): Promise<void> => {
  fetchAuthSession({ forceRefresh: true })
    .then((session) => {
      storeCurrentUserInfo(extractSession(session));
    })
    .catch((err) => {
      newRelic.logError(err);
    });
};

export const getJwT = (): string | null => {
  const userState = getUser(store.getState());

  return userState.token;
};

export const deleteUserInfo = (): void => {
  store.dispatch({
    type: USER__DELETE_CURRENT_JWT_TOKEN,
  });
};

export const signOut = async (): Promise<void> => {
  try {
    await signOutGen2();
  } finally {
    deleteUserInfo();
    store.dispatch(resetPolicies());
  }
};

export const getUserInfo = (): Object => getUser(store.getState());
